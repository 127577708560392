@import './colors.css';

.tab-content {
    margin-top: 0px !important;
}

.padding_top_row {
    padding-top: 1rem;
}

.margin_top_row {
    margin-top: 1rem;
}

.reservation_row {
    margin-top: 1rem;
}

.placement_tabs {
    border-bottom: 1px solid #dee2e6;
    border-left: 1px solid #dee2e6;
    border-right: 1px solid #dee2e6;
}

.placement_tabs_info {
    height: 220px;
}

.placements_tab_content {
    height: 220px;
    overflow-y: scroll;
}

.pending_reservations_tab_content {
    height: 220px;
    overflow-y: hidden;
    overflow-x: hidden;
}

.select_slot_info {
    margin-top: 1rem;
    margin-left: 1rem;
}

.placements_row {
    padding-top: 1rem;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 1rem;
}

.placements_col {
    padding-left: 5px;
    padding-right: 0px;
}

.placements {
    cursor: default;
    text-align: center;
    padding-top: .25rem;
    font-size: .7rem;
    line-height: 1.5;
    border-radius: .2rem;
    height: 31px;
    min-width: 31px;
    width: 100%;
    margin-bottom: 1rem;
}

.placements_big {
    cursor: default;
    text-align: center;
    padding-top: .25rem;
    font-size: .7rem;
    line-height: 1.5;
    border-radius: .2rem;
    height: 31px;
    width: 100%;
    margin-bottom: 1rem;
}

.placement_tag {
    position: absolute;
    top: -8px;
    right: -2px;
    z-index: 10;
    background-color: grey;
    border-color: 1px solid grey;
    color: white;
    border-radius: .25rem;
    padding: .25em .4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
}

.pending_reservation_tag {
    background-color: var(--declined--color);
    border-color: 1px solid var(--declined--color);
    color: white;
    border-radius: .25rem;
    font-size: 70%;
    padding: .2em .3em;
}

.placements-free {
    border: 1px solid #007bff;
    color: #007bff;
}

.placements-pending {
    border: 1px solid var(--pending--color);
    color: var(--pending--color);
}

.placements-pending-hover {
    box-shadow: 0px 0px 5px 1px var(--pending--color)
}

.placements-busy {
    border: 1px solid var(--busy--color);
    color: var(--busy--color);
}

.placements-busy-hover {
    box-shadow: 0px 0px 5px 1px var(--busy--color)
}

.placements-approved {
    border: 1px solid var(--approved--color);
    color: var(--approved--color);
}

.placements-approved-hover {
    box-shadow: 0px 0px 5px 1px var(--approved--color)
}

.placements_info_reservation {
    font-size: 0.7rem;
}


/*************************************************************************/
/*************************** SLOT JS *************************************/
/*************************************************************************/
.slots_title {
    font-size: 1rem;
}

.slot_row_checkbox {
    margin-top: 7px;
}

.slot_row_checkbox input[type=checkbox] {
    cursor: pointer;
    left: 84px;
    background: #26ca28;
}

.slot_download {
    cursor: pointer;
    margin-top: 10px
}

.slots_section {
    border: 1px solid #dee2e6;
}

.disable_slot_download_all {
    color: #dee2e6;
    cursor: not-allowed;
}

.slot_download_all {
    cursor: pointer;
}

.slot_border {
    margin-left: 1px;
    margin-right: 1px;
    margin-top: .5rem;
    border-bottom: 1px solid #dee2e6;
}

.slot_row_normal {
    margin-top: .5rem;
    margin-left: 0px !important;
    margin-right: -15px !important;
}

.slot_row_info {
    font-size: 0.8rem;
}

.slot_row_info_time {
    font-size: 0.7rem;
}

.full_switch {
    font-size: 0.9rem;
}

/* .custom-control-label::before {
    background-color: #28a745;
    cursor: pointer;
}

.custom-switch .custom-control-label::after {
    background-color: white;
}

.custom-control-input:checked~.custom-control-label::before {
    background-color: red;
} */

.slot_row_info_extra {
    font-size: 0.7rem;
}

.slot_row_download {
    float: right;
}

.firt_col_slots {
    padding-left: 4px !important;
}

.slot_row_selected {
    margin-top: .5rem;
    background-color: #d4ebff;
    border-radius: 10px;
}

.slot_further_info {
    /* float: right; */
    border: 2px solid black;
    font-size: 0.5rem;
    margin: 1px;
    font-weight: 800;
}

/*************************************************************************/
/*************************** RESERVATION JS ******************************/
/*************************************************************************/
.manual_icon_reservation_manager {
    float: right;
    font-size: 1rem;
    color: var(--primary--color);
    cursor: pointer;
    margin-top: 6px;
}

.reservation_reload_image {
    float: right;
    font-size: 1.7rem;
    margin-top: 6px;
    color: var(--primary--color);
    cursor: pointer;
}

.reservation_reload_image_disabled {
    float: right;
    font-size: 1.7rem;
    margin-top: 6px;
    color: var(--disabled--color);
    cursor: not-allowed;
}

.pending_reservation_row {
    margin-top: 0.5rem;
    margin-left: 0px;
    margin-right: 2px;
}

.pending_reservation_row_last {
    padding-top: 0.5rem;
    margin-left: 15px;
    margin-right: 15px;
    border-bottom: 1px solid #dee2e6;
}

.pending_reservation_customer {
    font-size: 0.9rem;
    padding-left: 5px;
    cursor: default;
}

.reservation_info {
    font-size: 0.8rem;
    padding-left: 5px;
    padding-right: 5px;
}

.reservation_info_placements_data {
    margin-left: 5px;
    cursor: default;
}

.reservation_info_clients_data {
    margin-left: 5px;
    cursor: default;
}

.reservation_info_note_img {
    margin-left: 10px;
    font-size: 1rem;
}

.reservation_info_time {
    margin-top: 0.3rem;
    font-size: 0.7rem;
    cursor: default;
}

.reservation_info_time_img {
    margin-right: 5px;
}

.pending_reservation_approve {
    font-size: 0.8rem;
    margin-top: 0.4rem;
}

/*************************************************************************/
/*************************** RESERVATION LIST ****************************/
/*************************************************************************/
.reservations_section {
    max-height: 600px;
    overflow-y: scroll;
}

.reservation_list {
    min-height: 730px;
}

.reservation_list_title {
    margin-bottom: 1rem;
    font-size: 0.9rem;
}

.reservation_list_filters {
    margin-bottom: 1rem;
    font-size: 0.8rem;
}

.reservation_list_name_filter {
    font-size: 0.8rem;
}

.reservation_list_status_filter_button {
    font-size: 0.9rem;
}

.reservation_sort {
    font-size: 0.8rem;
    padding: 0px;
    text-overflow: ellipsis;
}

.reservation_list_button {
    font-size: 0.7rem;
}

.reservation_list_button_list {
    font-size: 0.7rem;
    margin-right: 1px;
}

.reservation_pending_icon {
    color: var(--pending--color)
}

.reservation_approved_icon {
    color: var(--approved--color)
}

.reservation_declined_icon {
    color: var(--declined--color)
}

.reservation_busy_icon {
    color: var(--busy--color)
}

.reservation_done_icon {
    color: var(--done--color)
}

.reservation_client_cancel_icon {
    color: var(--client_cancel--color)
}

.slot_name {
    padding-right: 5px;
}

.slot_list_info_icon {
    color: var(--primary--color);
    font-size: .8rem;
    padding-bottom: 1px;
}

/*************************************************************************/
/*************************** ADD EDIT RESERVATION ************************/
/*************************************************************************/
.edit_header_icon_save {
    cursor: pointer;
    margin-right: 15px;
    font-size: 1.5rem;
    color: var(--primary--color)
}

.edit_header_icon_close {
    cursor: pointer;
    font-size: 1.5rem;
    color: grey
}

.edit_field_label {
    font-weight: bold;
}

.accordion_row {
    margin-top: 1rem;
    margin-left: 0rem !important;
    margin-right: 15px !important;
}

.select_placements_button {
    font-size: 0.5rem;
}

.reservation_placements_col {
    padding-left: 0px;
    padding-right: 0px;
}

.reservation_placements {
    text-align: center;
    padding-top: .25rem;
    padding-left: 0px;
    font-size: .8rem;
    line-height: 2;
    border-radius: .2rem;
    height: 38px;
    width: 38px;
    padding-bottom: 1rem;
    border: 1px solid var(--primary--color);
    color: var(--primary--color);
    position: relative;
}

.auto_assign_placements {
    padding-left: 0px;
    font-size: .8rem;
    margin-top: .5rem;
}

.slot_time_restrictions {
    font-size: .8rem;
}

.slot_time_restrictions_info {
    font-weight: 500;
}

.disabled_time {
    border-bottom: 1px solid #dee2e6;
    color: #6c757d;
    cursor: not-allowed;
}

.reservation_manager_header.modal-header {
    border-bottom: 0 !important;
    padding-left: 2rem;
    padding-top: 1rem;
}

.reservation_manager_body {
    padding-top: 1rem;
    padding-left: 2rem;
}

/*************************************************************************/
/*************************** SELECT PLACEMENTS ***************************/
/*************************************************************************/
.placements_to_select {
    text-align: center;
    padding: .25rem;
    font-size: .875rem;
    line-height: 2;
    border-radius: .2rem;
    height: 40px;
    width: 40px;
    margin-bottom: 1rem;
}

.placements_allowed {
    cursor: pointer;
    border: 1px solid var(--primary--color);
    color: var(--primary--color);
}

.placements_pending {
    cursor: not-allowed;
    border: 1px solid var(--pending--color);
    color: var(--pending--color);
}

.placements_not_allowed {
    cursor: not-allowed;
    color: var(--declined--color);
    border: 1px solid var(--declined--color);
}

.placements_already_selected {
    cursor: not-allowed;
    color: var(--disabled--color);
    border: 1px solid var(--disabled--color);
}

.placements_selected {
    text-align: center;
    padding: .25rem;
    font-size: .875rem;
    line-height: 2;
    border-radius: .2rem;
    height: 40px;
    width: 40px;
    margin-bottom: 1rem;
    border: 1px solid var(--primary--color);
    color: var(--primary--color);
    position: relative;
}

.no_placements_selected {
    padding: .25rem;
    font-size: .875rem;
    line-height: 2;
    border-radius: .2rem;
    height: 40px;
    margin-bottom: 1rem;
}

.deselect_icon {
    position: absolute;
    top: -8px;
    right: -8px;
    z-index: 10;
    background-color: white;
    cursor: pointer;
    color: grey;
}

/*************************************************************************/
/*************************** EXPORT **************************************/
/*************************************************************************/
.export_card {
    font-size: 0.7rem;
}

/*************************************************************************/
/*************************** SLOT INFO ***********************************/
/*************************************************************************/
.slot_info_main {
    font-size: 0.7rem;
}

.second_info {
    margin-left: 7px;
}

/*************************************************************************/
/*************************** SLOT INFO ***********************************/
/*************************************************************************/
.first_pending_reservations_date {
    font-size: 0.9rem;
    margin-top: 0.5rem;
    font-weight: 500;
    color: var(--primary--color);
}

.pending_reservations_date {
    font-size: 0.9rem;
    border-top: 1px solid black;
    padding-top: 0.2rem;
    margin-top: 0.2rem;
    font-weight: 500;
    color: var(--primary--color);
}

.first_pending_slot {
    font-size: 0.8rem;
    border-bottom: 1px solid var(--disabled--color);
    margin-top: 0.2rem;
    font-weight: 600;
}

.pending_slot {
    font-size: 0.8rem;
    border-top: 1px solid var(--disabled--color);
    border-bottom: 1px solid var(--disabled--color);
    margin-top: 0.2rem;
    font-weight: 600;
}

.slot_manager_header.modal-header {
    border-bottom: 0 !important;
    padding-left: 2rem;
    padding-top: 1rem;
}

/*************************************************************************/
/*************************** DATE PICKER *********************************/
/*************************************************************************/
.e-date-wrapper.e-custom-style.e-input-group:not(.e-success):not(.e-warning):not(.e-error),
.e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error) {
    border-color: var(--primary--color);
}

.e-time-wrapper.e-custom-style.e-input-group:not(.e-success):not(.e-warning):not(.e-error),
.e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error) {
    border-color: var(--primary--color);
}

.e-datetime-wrapper.e-custom-style.e-input-group:not(.e-success):not(.e-warning):not(.e-error),
.e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error) {
    border-color: var(--primary--color);
}

.slot_recurrence {
    padding-top: 2rem;
}